import React from 'react';
import { Routes, Route } from 'react-router-dom'


import Invitacion from './pages/Invitacion/Invitacion';
import Visita from './pages/Visita/Visita';

import AESDecryptionComponent from './pages/AESutils/AESDecrypt.js';
import AESEncryptionComponent from './pages/AESutils/AESEncrypt.js';

import { LoginPage } from './pages/Intern/Login.jsx';
import { HomePageIntern } from "./pages/Intern/Home.jsx";
import { EditPageIntern } from "./pages/Intern/Edit.jsx";
import { AddPageIntern } from "./pages/Intern/Add.jsx";

import { ProtectedRoute } from "./components/protectedRoute";
import { AuthProvider } from "./hooks/useAuth";

import PrivacyPolicy from './pages/Privacidad/privacidad.jsx';

import './App.css';
import Homepage from './Homepage';
import NewUser from './pages/new-user/new-user.jsx';
import ResetPassword from "./pages/reset-password/reset-pasword.jsx"

const NotFound = () => {
  return (
    <div className="App">
      <p>404 Not Found</p>
    </div>
  );
};

const App = () => {

  return (
    <AuthProvider>
        
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Invitacion/:jwtShortToken" element={<Invitacion />} />
        <Route path="/Visita/:token" element={<Visita />} />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/privacidad" element={<PrivacyPolicy />} />
        <Route path="/new-user/:jwtShortToken" element={<NewUser />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route 
          path="/aesencrypt"
          element={
            <ProtectedRoute>
              <AESEncryptionComponent />
            </ProtectedRoute>
          }
        />

        <Route 
          path="/aesdecrypt"
          element={
            <ProtectedRoute>
              <AESDecryptionComponent />
            </ProtectedRoute>
          }
        />

        <Route
          path="/secret"
          element={
            <ProtectedRoute>
              <HomePageIntern />
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit/:type/:id"
          element={
            <ProtectedRoute>
              <EditPageIntern />
            </ProtectedRoute>
          }
        />

        <Route
          path="/add/:type/:idPadre"
          element={
            <ProtectedRoute>
              <AddPageIntern />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
  );
};


export default App;