import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import config from "./../../config/config";
import RingM from "../../assets/home/img/RingM/RingM.png";

const ResetPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token"); // Obtén el token desde el query parameter

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);

  const handlePasswordSubmit = async () => {
    if (!token) {
      setError("Token no proporcionado. Verifica el enlace.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setPasswordMatch(false);
      return;
    }

    setPasswordMatch(true);
    setLoading(true);

    try {
      const response = await axios.post(
        `${config.REACT_APP_API}/auth/reset-password`,
        {
          token, // Token desde query parameter
          newPassword,
        }
      );

      console.log("Respuesta de la API:", response.data);
      setPasswordChanged(true);
    } catch (error) {
      console.error("Error al restablecer la contraseña:", error);
      setError(
        "Hubo un problema al restablecer tu contraseña. Verifica el token o inténtalo nuevamente."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        maxWidth: "400px",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      <img
        src={RingM}
        alt="RingM Logo"
        style={{ width: "100px", height: "auto", marginBottom: "20px" }}
      />

      <div
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: "1rem",
          borderRadius: "10px",
        }}
      >
        <h2
          style={{
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "20px",
            color: "#000000",
          }}
        >
          Restablecer contraseña
        </h2>

        {passwordChanged ? (
          <div
            style={{
              padding: "20px",
              textAlign: "center",
              backgroundColor: "#d4edda",
              color: "#155724",
              borderRadius: "5px",
              marginTop: "20px",
            }}
          >
            <p>¡Contraseña restablecida exitosamente!</p>
          </div>
        ) : (
          <>
            <div style={{ marginBottom: "15px" }}>
              <label htmlFor="newPassword" style={{ color: "#000000" }}>
                Nueva contraseña
              </label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Introduce tu nueva contraseña"
                required
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "5px 0",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label htmlFor="confirmPassword" style={{ color: "#000000" }}>
                Confirmar nueva contraseña
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirma tu nueva contraseña"
                required
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "5px 0",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            {!passwordMatch && (
              <p style={{ color: "red", marginBottom: "10px" }}>
                Las contraseñas no coinciden
              </p>
            )}
            {error && (
              <p style={{ color: "red", marginBottom: "10px" }}>{error}</p>
            )}

            <button
              onClick={handlePasswordSubmit}
              disabled={newPassword === "" || confirmPassword === "" || loading}
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: loading ? "#cccccc" : "#007bff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                fontWeight: "bold",
                cursor: loading ? "not-allowed" : "pointer",
              }}
            >
              {loading ? "Cargando..." : "Restablecer contraseña"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;