import RingM from "../../assets/home/img/RingM 2/RingM.png";
import SecurityIcon from "../../assets/home/icon/Seguridad.svg";
import GestionaIcon from "../../assets/home/icon/Gestiona.svg";
import GestionaIcon1 from "../../assets/home/icon/Gestiona (1).svg";
import Residentes from "../../assets/home/img/Residentes/Residentes.png";
import Arrow from "../../assets/home/SVGfiles/Arrow-3.svg";
import Iphonebackground from '../../assets/home/img/Iphonefondogris/BG - Mobile@2x.png';
import Check from '../../assets/home/SVGfiles/Check-1.svg';
import CasaPlaya from '../../assets/home/img/CasasRingMe/Casa de la playa.png';
import CasaManuel from '../../assets/home/img/CasasRingMe/Casa de Manuel.png';
import CasaPrincipal from '../../assets/home/img/CasasRingMe/Casa Principal.png';
import Notificaciones from '../../assets/home/img/notificaciones/Notificaciones.png';
import Invitaciones from '../../assets/home/img/notificaciones/Invitaciones.png';
import './Product2.css';

const Product2 = () => {
    return (
        <>
            <div className="container-product2" id="descubrelo">
                <div className="text-column">
                    <p className='descubrelo-text'>
                        DESCÚBRELO
                    </p>
                    <h1 className='mejoraseguridad-text'>
                        Mejora tu seguridad y
                    </h1>
                    <h1 className='mejoraseguridad-text'>
                        control con RingM
                    </h1>
                
                    <div className="rectangulo1">
                        <div className="iphone-residentes-image-container">
                            <img src={Residentes} alt="iphone residentes" className="iphone-residentes-image" />
                        </div>
                        <div className="text-container-residentes">
                            <div className="">
                                <img src={Check} alt="Check Icon" className='check-image'/>
                                <p className="Residentes-title">
                                    Residentes y visitas
                                </p>
                            </div>
                            <div className="Residentes-text">
                                <p className='blue-color-text'>Podrás invitar a tus familiares y amigos a formar parte como </p>
                                <p className='blue-color-text'>residentes, brindándoles un acceso fácil y conveniente,</p>
                                <p className='blue-color-text'>Además, controla el acceso temporal para visitas</p>
                                <p className='blue-color-text'>ocasionales. Gestiona de forma segura quién puede entrar</p>
                                <p className='blue-color-text'> a tu hogar.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle-column">
                    <div className="rectangulo2">
                        <div className="multiples-hogares-image-container">
                            <img src={CasaPlaya} alt="Casa playa" className="casa-playa-image" />
                            <img src={CasaManuel} alt="Casa manuel" className="casa-manuel-image" />
                            <img src={CasaPrincipal} alt="Casa principal" className="casa-principal-image" />
                        </div>

                        <div className='text-container-multiples-hogares'>
                            <div className="">
                                <img src={Check} alt="Check Icon" className="muitiples-hogares-check"/>
                                <p className="multiples-hogares-title">
                                    Multiples hogares
                                </p>
                            </div>
                            <div className='multiples-hogares-text'>
                                <p className='blue-color-text'>Puedes formar parte de múltiples hogares. Mantén tus</p>
                                <p className='blue-color-text'>conexiones cercanas y seguras, y accede fácilmente a</p>
                                <p className='blue-color-text'>cada uno de ellos desde una sola aplicación.</p>
                            </div>
                        </div>
                    </div>

                    <div className="rectangulo3">
                        <div className="notificaciones-e-invitaciones-image-container">
                            <img src={Notificaciones} alt="Notificaciones" className="notificaciones-image" />
                            <img src={Invitaciones} alt="Invitaciones" className="invitaciones-image" />
                        </div>

                        <div className='text-container-control-invitaciones'>
                            <div className="">
                                <img src={Check} alt="Check Icon" className="control-invitaciones-check"/>
                                <p className="control-invitaciones-title">
                                    Control de invitaciones
                                </p>
                            </div>
                            <div className='control-invitaciones-text'>
                                <p className='blue-color-text'>Cuando un usuario te añada como visitante o residente,</p>
                                <p className='blue-color-text'>recibirás una notificación de invitación. Tendrás el poder</p>
                                <p className='blue-color-text'>de aceptar o rechazar.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Product2;
